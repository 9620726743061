













































































import { Service } from '@app/models';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ModuleModule } from '../../store/modules/module-module';

@Component
export default class EditorService extends Vue {
  public service: Service = {
    active: false,
    category: '',
    description: '',
    id: '',
    name: '',
    order: 0,
    price: 0,
    structure: 'off-by-default',
  };

  private module = getModule(ModuleModule);

  public get id() {
    return this.$route.params.id || this.$firebase.generateId();
  }

  public get saveIcon() {
    return this.service.id ? 'fas:save' : 'fas:plus';
  }

  public get saveLabel() {
    return this.service.id
      ? this.$t('save')
      : this.$t('add-item', { item: this.$t('service') });
  }

  mounted() {
    this.watchRoute();
  }

  @Watch('$route.params.id')
  private watchRoute() {
    const service = this.module.services.find(s => s.id === this.id);
    if (service) this.service = { ...service };
  }

  public async submit() {
    const id = this.service.id || this.$firebase.generateId();
    const service = { ...this.service, id };

    await this.$firebase.doc(`service/${id}`).set(service, { merge: true });
    this.$router.push('/editor');
  }
}
